<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="wrapper--static">
      <div class="container py-4">
        <div class="d-flex flex-wrap">
          <calculator-form class="col-12 col-md-5 mb-2" ref="calculatorForm"></calculator-form>
          <calculator-result class="col-12 col-md-7"></calculator-result>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapState } from 'vuex';
const CalculatorForm = () => import('@/components/mortgage/calculator-form');
const CalculatorResult = () => import('@/components/mortgage/calculator-result');

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    CalculatorForm,
    CalculatorResult,
  },
  computed: {
    ...mapState({}),
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('mortgage.mortgageCalculator'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
